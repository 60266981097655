f<template>
  <div>
    <FiltersTemplate
      :inputFilters="inputFilters"
      :adminFilters="adminFilters"
      apiSlug="follower"
    />

    <AlertExpirePassword />

    <StatsPage
      v-bind:APIslug="'follower'"
      v-bind:tabsData="tabsData"
      v-bind:displayChart="false"
      v-bind:inputFilters="inputFilters"
      v-bind:adminFilters="adminFilters"
      class="kt-container mt-5"
    ></StatsPage>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import StatsPage from "@/components/layout/StatsPage";
import FiltersTemplate from "@/components/subheaders/filters/FiltersTemplate";
import {
  adminFilters,
  inputFilters
} from "@/common/config/filters/audiencesFollowers";
import AlertExpirePassword from "@/components/AlertExpirePassword";

const normalizeFollowersDataTable = function(periodDatas) {
  delete periodDatas["total"];
  delete periodDatas["debugQuery"];
  let results = [];

  for (var key in periodDatas) {
    results.push({
      date: key,
      followers: periodDatas[key]["followers"],
      shortcuts: periodDatas[key]["shortcuts"]
    });
  }
  return results;
};

export default {
  name: "Followers",

  components: {
    StatsPage,
    FiltersTemplate,
    AlertExpirePassword
  },

  data() {
    return {
      tabsData: [
        {
          loading: true,
          sizes: {
            default: 12,
            sm: 4,
            lg: 3
          },
          title: "New followers",
          primaryStat: "NaN",
          previousCompare: "NaN",
          iconSVG: "assets/media/icons/svg/Communication/Group2.svg",
          iconSVGColor: "primary",
          cardClass: "bg-pi",
          value: "followers",
          selected: true,
          normalizeDataTable: function(datas) {
            return normalizeFollowersDataTable(datas);
          },
          chartDataToDisplay: [
            {
              value: "followers",
              color: "#FF961D",
              label: "New followers"
            }
          ]
        },
        {
          loading: true,
          sizes: {
            default: 12,
            sm: 4,
            lg: 3
          },
          title: "Webapp Installations",
          primaryStat: "NaN",
          previousCompare: "NaN",
          iconSVG: "assets/media/icons/svg/General/Thunder-move.svg",
          iconSVGColor: "info",
          theme: "light",
          value: "shortcuts",
          selected: false,
          normalizeDataTable: function(datas) {
            return normalizeFollowersDataTable(datas);
          },
          chartDataToDisplay: [
            {
              value: "shortcuts",
              color: "#FF961D",
              label: "Webapp Installations"
            }
          ]
        }
      ],
      adminFilters: adminFilters,
      inputFilters: inputFilters
    };
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Followers",
        pageCategory: "Analytics"
      }
    ]);
  }
};
</script>
